.footer {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    font-size: 0.75rem;
    /* background-color: var(--dark-sienna); */
    /* color: var(--camel); */
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    /* background-image: linear-gradient(to right, #e56a54, #202a44) !important; */
}

.footer button {
    background-color: transparent;
    border: 0;
    margin: 0;
    padding: 0;
    /* color: var(--camel); */
}

/* .footer button:hover { */
/*     color: var(--salmon); */
/* } */

.footer-left {
    text-align: left;
}

.footer-middle {
    display: flex;
    align-items: center;
}

.footer-middle > * {
    margin: 0 2px;
}

.footer-middle > .progress {
    width: 100px;
    height: 0.75rem;
}

.tooltip-container {
    display: flex;
    flex-direction: row;
    font-size: .75rem;
}

.tooltip-container > .tooltip-column {
    flex: 1 0 auto;
    padding: 0 2px;
}

.tooltip-container > .tooltip-column > * {
    flex: 1 0 auto;
}

.footer-right {
    text-align: right;
}
