:root {
  /* CSS HEX */
  --dark-sienna: #341c1cff;
  --black-coffee: #49393bff;
  --dark-khaki: #c0c781ff;
  --camel: #c59b76ff;
  --salmon: #e9806eff;
}

.color1 {
  color: #ffba54;
}
.color2 {
  color: #fbdc51;
}
.color3 {
  color: #005399;
}
.color4 {
  color: #00a3a8;
}
.color5 {
  color: #00f6be;
}

body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100vh;
  /* font-family: "Inter", sans-serif; */
}

a {
  /* color: #333; */
  text-decoration: none;
  font-size: 0.8rem;
}

table {
  font-size: 0.8rem;
}

/* a:hover { */
/*     color: #777; */
/* } */

#root {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
}

.outer-container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.main-container {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
}

.content {
  flex: 1 0 auto;
  width: 100%;
}

.table-condensed {
  width: 50%;
  max-width: 400px;
}

.table-small {
  max-width: 600px;
}

.table-centered {
  margin-left: auto;
  margin-right: auto;
}

.clickable {
  cursor: pointer;
}

/* .modal-header > button.close { */
/*   background-color: transparent; */
/*   border: 0; */
/*   font-size: 1.5rem; */
/*   color: #aaa; */
/* } */

.page-header {
  font-size: 2rem;
  border-bottom: 1px solid #333;
  padding-bottom: 4px;
}

/* autocomplete dropdown */
.autocomplete > div {
  z-index: 1;
}

/* autocomplete items */
.autocomplete > div > div {
  font-size: .75rem;
}

.modal-title {
  font-size: .90rem;
}

.contact-container {
  margin: 2rem auto;
  max-width: 640px;
}

.contact-form > .contact-buttons {
  display: flex;
  justify-content: flex-end;
}

.contact-form > .btn {
  flex: 0 1 auto;
}

@media (max-width: 640px) {
  .page-header {
    font-size: 1.5rem;
  }
}

/* @media (min-width: 640px) { */
/*   .main-container { */
/*     position: relative; */
/*     left: 240px; */
/*   } */
/* } */

@media (min-width: 1280px) {
  .main-container {
    position: relative;
    left: 240px;
    width: calc(100% - 240px);
  }
}
