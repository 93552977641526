.filters-footer {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    width: 100%;
}

.filters-spacer {
    display: inline-block;
    flex: 1 0 auto;
}

.filters-container {
    font-size: 0.85rem;
    /* background-color: var(--black-coffee); */
    /* color: var(--camel); */
    /* border: 1px solid var(--dark-sienna); */
    border-radius: 4px;
}

.filters-header .btn {
    font-size: 0.85rem;
    font-weight: bold;
    /* color: #ccc; */
}

/* .filters-header .btn:hover { */
/*     color: #eee; */
/* } */

.filters-header .click-to-open {
    cursor: pointer;
}

.filters-text {
    font-size: 0.85rem;
    /* color: #eee; */
    margin: 4px;
    vertical-align: middle;
}

.filters-form {
    /* background-color: lightgray; */
    /* color: var(--dark-sienna); */
    padding: 0 4px 4px 4px;
    border-radius: 0 0 4px 4px;
}

.filters-form label {
    font-size: 0.75rem;
}

.filters-form input,
.filters-form select,
.filters-form .input-group {
    font-size: 0.75rem;
    padding-top: 4px;
    /* background-color: var(--camel); */
    /* color: var(--dark-sienna); */
    /* border: 1px solid var(--dark-sienna); */
}

/* .filters-form input:focus { */
/*     background-color: var(--camel); */
/* } */

.filters-form .location-filter {
    /* background-color: lightgray; */
    margin-left: 0px;
    border-radius: 4px;
}

.nav-pills .nav-link {
    /* color: var(--camel); */
    cursor: pointer;
    border-radius: 0;
}

/* .nav-pills .nav-link.active { */
/*     color: var(--dark-sienna); */
/*     background-color: var(--camel); */
/* } */

@media only screen and (max-width: 600px) {
  .filters-header .col {
    text-align: left !important;
  }
}

@media only screen and (min-width: 600px) {
  .filters-container {
    width: 100%;
  }
}
