.login-container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    justify-items: center;
    align-items: center;
    height: 80vh;
}

.login-form {
    width: 75%;
    max-width: 400px;
}

.login-body {
    padding: 2rem;
    border-radius: 0.5rem;
    /* box-shadow: 2px 2px #ddd; */
    /* background-color: #f5f5f5; */
}

.login-body > .form-header {
    text-align: center;
    margin-bottom: 1rem;
}

.logo-container {
  margin: 2rem;
}

.login-body > .form-header > h6 {
    margin: 1rem 0;
}

.login-body > .form-controls {
    margin: 1rem 0;
}

.login-body > .form-controls > input {
    margin: .5rem 0;
}

.login-body > .form-buttons {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 1rem;
}
