.select-object-list-container {
  overflow-y: scroll;
}

.select-object-table tr {
  cursor: pointer;
}

.select-object-table tr.selected {
  background-color: var(--bs-warning);
}

.select-object-table tr.disabled > td {
  color: var(--bs-white);
  background-color: var(--bs-danger);
  cursor: default;
}

.select-object-table td {
  font-size: 0.85rem;
  /* white-space: nowrap; */
  text-overflow: ellipsis;
}

.selected-record {
  font-size: 0.85rem;
  max-width: 500px;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
}

.search-text-highlight {
  background-color: yellow;
  color: red;
  font-weight: 500;
}
