.smen-container {
  display: flex;
  flex-direction: row;
}

.smen-list {
  flex: 1;
}

.smen-details {
  flex: 1;
  padding: 0.5rem;
  margin: 0.5rem;
  border-left: 1px solid lightgray;
}

.smen-details > h6 {
  font-weight: bold;
  border-bottom: 2px solid black;
}

.no-record-selected {
  font-size: 0.85rem;
  color: var(--bs-dark);
}

/* .smen-list td, */
.assignment-record {
  font-size: 0.75rem;
}

.smen-list tr,
.assignment-record {
  cursor: pointer;
}

.assignments-container {
  display: flex;
  flex-direction: row;
}

.assignment-column {
  flex: 1;
  font-weight: bold;
}

.assignment-column-content {
  max-height: 60vh;
  overflow-y: auto;
}

/* columns divider border */
.assignment-column:not(:last-child) {
  border-right: 1px solid lightgray;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
}

.assignment-record {
  font-weight: normal;
  padding: 0 0.15rem;
  margin: 0 0.25rem;
}

.assignment-record:hover {
  background-color: lightgray;
}

.selected-sman {
  /* needs !important to override the :hover above */
  background-color: var(--camel) !important;
}

.buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 1rem 0;
  border-top: 1px solid lightgray;
}

.buttons-container > button {
  margin-left: 0.25rem;
}
