.pie-chart {
    /* flex: 0 1 auto; */
    font-size: 0.75rem;
}

.line-chart {
    /* flex: 1 0 auto; */
    font-size: 0.75rem;
}

.radar-chart {
    /* flex: 1 0 auto; */
    font-size: 0.75rem;
}
