@media print {
    .pe-no-print {
        display: none !important;
    }

    .pe-preserve-ancestor {
        display: block !important;
        margin: 0 !important;
        padding: 0 !important;
        border: none !important;
        box-shadow: none !important;
    }
}