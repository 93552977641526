.alert {
  position: fixed !important;
  right: 1em;
  top: 5em;
  max-width: 400px;
  font-size: 0.75rem;
}

.alert > h5 {
  font-size: 0.85rem;
  font-weight: bold;
}
