.dashboard-panel {
  display: flex;
  flex-direction: column;
  margin: .5rem 0;
  border: 1px solid #ddd;
}

.dashboard-panel-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #202a44;
  color: #eee;
  margin: 0;
  padding: .5rem;
}

.dashboard-panel-title-left {
    font-size: .9rem;
    font-weight: 700;
}

.dashboard-panel-title-right {
    font-size: 1rem;
    font-weight: 700;
}

.dashboard-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: .5rem;
  flex-wrap: wrap;
  /* background-color: red; */
}

.dashboard-header > .section {
  display: flex;
  flex-direction: column;
  /* background-color: blue; */
  text-align: center;
  padding-left: .5rem;
  padding-right: .5rem;
  border-left: 1px solid #999;
}

.dashboard-header > .section:last-child {
  border: 0;
}

.dashboard-header > .section.grow {
  flex-grow: 1;
}

.dashboard-header > .section > .title {
  font-size: .8rem;
}

.dashboard-header > .section > .value {
    font-size: 1.25rem;
    font-weight: bold;
    /* text-align: end; */
}

.dashboard-totals {
    font-size: .8rem;
}

.dashboard-totals.center {
    text-align: center;
}

.dashboard-totals.right {
    text-align: end;
}

.dashboard-totals > .name {
    display: inline;
}

.dashboard-totals > .value {
    display: inline;
    font-size: 1rem;
    font-weight: bold;
    text-align: end;
}

.dashboard-subheader {
    font-size: .9rem;
    margin: 0 .5rem;
}

.recharts-responsive-container {
    min-height: 150px;
}

.area-chart,
.bar-chart {
    font-size: 0.75rem;
}

@media (max-width: 600px) {
  .dashboard-header > .section > .value {
      font-size: 1rem;
  }
  .dashboard-header > .print-button {
    margin: 0 auto;
  }
}

@media print {
    .panel-span.col-md-6 {
        width: 100%; /* 1 full-width column for panels */
    }
}
