.cards-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

/* .cards-container > * { */
/*   flex-basis: 240px; */
/* } */

.sticker-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: end;
  padding: 10px;
  margin: 5px;
  border-radius: 4px;
  /* background-color: #fed; */
  /* border: 1px solid #edc; */
}

.sticker-header {
  display: flex;
  flex: 1 0 auto;
  flex-direction: row;
  align-items: center;
}

.sticker-title {
  flex: 0 1 auto;
  font-size: 0.75rem;
  /* color: #555; */
  line-height: 100%;
  /* text-align: start; */
  text-align: center;
}

.sticker-icon {
  flex: 0 1 auto;
  padding-right: 0.5rem;
  display: none;
  /* color: #888; */
}

.sticker-content {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  text-align: center;
}

.sticker-number {
  font-size: 2rem;
  font-weight: 600;
  /* color: #333; */
}

.sticker-subtitle {
  flex: 0 1 auto;
  font-size: 1rem;
  font-weight: 600;
  /* color: #666; */
  line-height: 75%;
}

@media (max-width: 600px) {
  .cards-container > * {
   flex-basis: 0; 
  }
  .sticker-icon {
    font-size: 2rem;
  }
  .sticker-number {
    font-size: 1.5rem;
  }
}
