.download-category {
  font-weight: bold;
  font-size: 1.25rem;
  padding-bottom: .75rem;
}

.download-icon {
  color: #999;
  margin-right: .75rem;
}

.download-entry {
  line-height: 2rem;
}

.download-entry:hover {
  font-weight: bold;
}

.download-col {
  margin-top: 1rem;
  margin-left: .25rem;
  margin-right: -.25rem;
}
