// Darkly 5.2.0
// Bootswatch

$theme: "darkly" !default;

//
// Color system
//

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #ebebeb !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #888 !default;
$gray-700: #444 !default;
$gray-800: #303030 !default;
$gray-900: #222 !default;
$black:    #000 !default;

$blue:    #375a7f !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #e74c3c !default;
$orange:  #fd7e14 !default;
$yellow:  #f39c12 !default;
$green:   #00bc8c !default;
$teal:    #20c997 !default;
$cyan:    #3498db !default;

$trilia_red: #e56a54;
$trilia_red_dark: darken($trilia_red, 20%);
$trilia_red_light: lighten($trilia_red, 20%);
$trilia_blue: #202a44;
$trilia_blue_dark: darken($trilia_blue, 20%);
$trilia_blue_light: lighten($trilia_blue, 20%);

$primary:       $yellow !default;
$secondary:     $trilia_blue !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-500 !default;
$dark:          $gray-800 !default;

$min-contrast-ratio:   1.9 !default;

$light-purple:   lighten($purple, 10%) !default;
$lighter-purple: lighten($purple, 20%) !default;
$light-yellow:   lighten($yellow, 20%) !default;
$lighter-yellow: lighten($yellow, 40%) !default;

// Constants

$l4a-navbar-bg: $trilia_blue;
$l4a-navbar-icon-color: $light-yellow;
// $l4a-sidebar-bg: $gray-800;
// $l4a-sidebar-header-color: $light-yellow;
// $l4a-sidebar-link-selected-color: $gray-800;
// $l4a-sidebar-link-selected-bg: $yellow;
// $l4a-sidebar-link-hover-color: $gray-700;
// $l4a-sidebar-link-hover-bg: $light-yellow;
$l4a-sidebar-bg: $gray-100;
$l4a-sidebar-header-color: $trilia_red;
$l4a-sidebar-color: $trilia_blue;
$l4a-sidebar-link-color: $trilia_blue_light;
$l4a-sidebar-link-selected-color: $gray-200;
$l4a-sidebar-link-selected-bg: $trilia_blue;
$l4a-sidebar-link-hover-color: $gray-400;
$l4a-sidebar-link-hover-bg: $trilia_blue_light;

// $nav-pills-link-active-bg:  $yellow;
$nav-pills-link-active-bg:  $trilia_blue_light;
$nav-link-color:            $gray-600; // nav-pills unselected pill
$nav-link-hover-color:      $orange; // nav-pills unselected pill

// Body

$body-bg:                   $gray-100 !default;
$body-color:                $gray-900 !default;

// Links

$link-color:                $gray-200 !default;

// Fonts

// stylelint-disable-next-line value-keyword-case
$font-family-sans-serif:      Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$h1-font-size:                3rem !default;
$h2-font-size:                2.5rem !default;
$h3-font-size:                2rem !default;
$text-muted:                  $gray-600 !default;

// Tables

$table-border-color:          $gray-500 !default;
// $table-bg:                    $gray-200 !default;
// $table-striped-bg:            $gray-300 !default;

$table-bg-scale:              0 !default;

// Forms

$input-bg:                          $white !default;
$input-color:                       $gray-800 !default;
$input-border-color:                $body-bg !default;
$input-group-addon-color:           $gray-500 !default;
$input-group-addon-bg:              $gray-700 !default;

$form-check-input-bg:                     $white !default;
$form-check-input-border:                 none !default;

$form-file-button-color:          $white !default;

// Dropdowns

$dropdown-bg:                       $gray-900 !default;
$dropdown-border-color:             $gray-700 !default;
$dropdown-divider-bg:               $gray-700 !default;
$dropdown-link-color:               $white !default;
$dropdown-link-hover-color:         $white !default;
$dropdown-link-hover-bg:            $primary !default;
// Navs

$nav-link-padding-x:                1rem !default;
$nav-link-disabled-color:           $gray-500 !default;
$nav-tabs-border-color:             $gray-300 !default;
$nav-tabs-link-hover-border-color:  $nav-tabs-border-color $nav-tabs-border-color transparent !default;
$nav-tabs-link-active-color:        $white !default;
$nav-tabs-link-active-border-color: $nav-tabs-border-color $nav-tabs-border-color transparent !default;
// Navbar

$navbar-padding-y:                  1rem !default;
$navbar-dark-color:                 rgba($white, .6) !default;
$navbar-dark-hover-color:           $white !default;
$navbar-light-color:                rgba($gray-900, .7) !default;
$navbar-light-hover-color:          $gray-900 !default;
$navbar-light-active-color:         $gray-900 !default;
$navbar-light-toggler-border-color: rgba($gray-900, .1) !default;

// Pagination

$pagination-color:                  $white !default;
$pagination-bg:                     $success !default;
$pagination-border-width:           0 !default;
$pagination-border-color:           transparent !default;
$pagination-hover-color:            $white !default;
$pagination-hover-bg:               lighten($success, 10%) !default;
$pagination-hover-border-color:     transparent !default;
$pagination-active-bg:              $pagination-hover-bg !default;
$pagination-active-border-color:    transparent !default;
$pagination-disabled-color:         $white !default;
$pagination-disabled-bg:            darken($success, 15%) !default;
$pagination-disabled-border-color:  transparent !default;

// Cards

$card-cap-bg:                       $gray-700 !default;
$card-bg:                           $gray-800 !default;

// Popovers

$popover-bg:                        $gray-800 !default;
$popover-header-bg:                 $gray-700 !default;

// Toasts

$toast-background-color:            $gray-700 !default;
$toast-header-background-color:     $gray-800 !default;

// Modals

$modal-content-bg:                  $gray-200 !default;
$modal-content-border-color:        $gray-600 !default;
$modal-header-border-color:         $gray-100 !default;

// Progress bars

$progress-bg:                       $gray-700 !default;

// List group

$list-group-color:                  $body-color !default;
$list-group-bg:                     $gray-800 !default;
$list-group-border-color:           $gray-700 !default;
$list-group-hover-bg:               $gray-700 !default;
$list-group-action-hover-color:     $list-group-color !default;
$list-group-action-active-bg:       $gray-900 !default;

// Breadcrumbs

$breadcrumb-padding-y:              .375rem !default;
$breadcrumb-padding-x:              .75rem !default;
$breadcrumb-bg:                     $gray-700 !default;
$breadcrumb-border-radius:          .25rem !default;

// Close

// $btn-close-color:            $white !default;
// $btn-close-opacity:          .4 !default;
// $btn-close-hover-opacity:    1 !default;

// Code

$pre-color:                         inherit !default;

// LOGIN

.login-body {
    background-color: $trilia_blue_light;
    border: 1px solid $trilia_blue;
    color: $gray-200;
}

.login-body > .form-header {
    color: $light-yellow;
}

// GENERIC

.page-header {
    color: $gray-700;
}

// .modal-header {
//     background-color: $gray-700;
//     color: $gray-300;
// }

// SIDEBAR
.sidebar, .sidebar-container {
    background-color: $l4a-sidebar-bg;
    color: $l4a-sidebar-color;
}

.sidebar-toggler {
  background-color: $trilia_red;
  color: $gray-100;
}

.sidebar-text, .sidebar-link > a {
    color: $l4a-sidebar-color;
}

.sidebar-header .sidebar-text{
    color: $l4a-sidebar-header-color;
    font-weight: bold;
    font-size: .80rem;
}

.sidebar-link.selected {
    background-color: $l4a-sidebar-link-selected-bg;
}

.sidebar-link.selected > a, .sidebar-link.selected .sidebar-text {
    color: $l4a-sidebar-link-selected-color;
}

.sidebar-link:hover {
    background-color: $l4a-sidebar-link-hover-bg;
}

.sidebar-link > a:hover .sidebar-text {
    color: $l4a-sidebar-link-hover-color;
}

.sidebar-link > a:hover {
    color: $l4a-sidebar-link-hover-color;
}

// NAVBAR

.navbar, .navbar-dark {
  background-color: $l4a-navbar-bg;
}

.navbar-source-list > .form-label {
    color: $l4a-navbar-icon-color;
}

// fix dropdown item in navbar broken hover color
.navbar-dark .navbar-nav .dropdown-item > a:hover {
    color: $gray-700;
}

// FILTERS

.filters-container {
    // background-color: $light-yellow;
    // border: 1px solid $lighter-yellow;
    background-color: $trilia_blue;
    border: 1px solid $trilia_blue_light;
    color: $gray-200;
}

.filters-content {
    background-color: $gray-200;
    color: $gray-700;
}

.filters-header .btn {
    color: $gray-200;
}

.filters-header .btn:hover {
    color: $white;
}

.filters-form {
    border-top: 1px solid $gray-400;
}

.filters-container .nav-pills .nav-link {
    border-right: 1px solid $trilia_blue;
}

.filters-form input,
.filters-form select,
.filters-form .input-group {
    background-color: $gray-100;
}

// TABLES

table > thead > tr {
    background-color: $trilia_blue_light;
}

table.details > thead > tr,
table.details-lg > thead > tr {
    background-color: $gray-600;
}

table > thead > tr > th {
    color: $gray-200;
}

.transaction-row.link {
    background-color: transparent;
    color: $gray-200;
}

.transaction-row td a{
    color: $gray-800;
}

// CARDS

.sticker-container {
    // background-color: $lighter-yellow;
    // border: 1px solid $light-yellow;
    background-color: $trilia_blue_light;
    border: 1px solid $trilia_blue_dark;
}

.sticker-title {
    color: $gray-300;
}

.sticker-subtitle {
    color: $gray-400;
}

.sticker-icon {
    color: $light-yellow;
}

.sticker-number {
    color: $white;
}

// FOOTER

.footer {
    background-color: $trilia_blue;
    color: $gray-200;
}

// SALESMEN ASSIGNMENT

.assignment-record.invalid {
  color: $red;
}

// DOWNLOADS

.download-col {
  border-left: 4px solid $trilia_red;
}
