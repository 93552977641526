.errors-row {
    font-size: 0.75rem;
}

.errors-row > .serialno {
    font-weight: bold;
}

.errors-row:hover {
    cursor: pointer;
}

.errors-row.link > td {
    font-weight: 600;
    color: #ccc;
    background: transparent;
    border: none;
    white-space: nowrap;
}

.errors-row.no-wrap {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.errors-container {
    font-size: 0.9rem;
}

.error-details > .row {
    font-size: 0.85rem;
}

.error-details > .row > .col-md-9 {
    font-weight: bold;
}

.errors-icon {
    color: var(--dark-khaki);
    margin-right: 1rem;
}
