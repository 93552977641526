.cell-shrink {
  min-width: 15%;
}

.announcement-read {
  cursor: pointer;
}

.announcement-unread {
  font-weight: bold;
  cursor: pointer;
}

.announcement {
  width: 60%;
  margin: 1rem auto;
}

.title {
  font-size: 1.5rem;
  font-weight: bold;
  width: 100%;
}

.author {
  font-size: 0.85rem;
  font-weight: normal;
  color: #999;
}

.message {
  border-top: 1px solid #ccc;
  padding-top: 1rem;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.buttons:last-child {
  justify-self: end;
}
