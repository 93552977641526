.transaction-row {
    font-size: 0.75rem;
}

.transaction-row > th,
.transaction-row > td {
    white-space: nowrap;
}

.transaction-row.selected {
    color: red !important;
}

.transaction-row.link {
    font-size: 0.8rem;
    font-weight: 600;
    border: none;
    white-space: nowrap;
}

.table.details {
    margin-left: auto;
}

/* .table.details thead { */
/*     background-color: var(--camel); */
/* } */

/* .table.details tbody { */
/*     background-color: #f8f8f8; */
/* } */

.table.details-md {
    margin-left: auto;
    max-width: 500px;
}

.table.details-lg {
    margin-left: auto;
    max-width: 800px;
}

.table.details .contract {
    width: 10%;
}

.table.details .rightAlign {
    text-align: right;
}

.table.details .bold {
    font-weight: bold;
}

.exporters-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-bottom: 1rem;
    /* border-top: 1px solid lightgray; */
}

.exporters-container > button {
    margin-left: 0.25rem;
}

@media only screen and (max-width: 600px) {
  th {
    display: none;
  }

  .transaction-row {
    padding: 0.75rem 0rem;
  }

  .transaction-row > td {
    /* display: block; */
    display: grid;
    grid-template-columns: 18ch auto;
    padding: 0rem 1rem;
    text-align: left;
    border: none;
    white-space: initial;
    word-wrap: break-word;
  }

  .transaction-row > td::before {
    content: attr(data-cell) ": ";
    font-weight: bold;
  }

  .transaction-row > td:first-child {
    padding-top: 0.5rem;
  }

  .transaction-row > td:last-child {
    padding-bottom: 0.5rem;
  }

  .exporters-container {
    display: none;
  }
}
