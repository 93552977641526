.sidebar-container {
  display: block;
  height: 90%;
  width: 240px;
  position: fixed;
  z-index: 1;
  top: 4rem;
  left: 0;
  /* background-color: red; */
  padding-top: 60px;
  border-right: 1px solid #999;
  transition: 0.25s;
}

.sidebar-container .closebtn {
  position: absolute;
  top: 0;
  right: 1rem;
  font-size: 2rem;
  color: #999;
  border: 0;
  background-color: initial;
}

.sidebar-toggler {
  position: absolute;
  top: 50%;
  right: -1rem;
  width: 1rem;
  height: 3rem;
  border-radius: 0 8px 8px 0;
  padding: 0;
  font-weight: bold;
}

.sidebar-container .logo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem;
  margin: 0 0.25rem;
}

.sidebar-container .logo img {
  transition: visibility 0.25s linear;
  max-width: 120px;
}

.sidebar-container .hamburger-button {
  cursor: pointer;
}

.sidebar {
  flex: 0 0 auto;
  /* background-color: #222; */
  /* color: #ccc; */
  padding: 0;
  margin: 0;
  font-size: 0.9rem;
  overflow-x: hidden;
}

.sidebar > ul {
  padding-left: 1rem;
}

.sidebar > ul > ul {
  margin-left: 1rem;
  padding-left: 0;
  font-size: 85%;
  /* border-left: 2px solid var(--dark-sienna); */
}

.sidebar li {
  display: inline-block;
  width: 100%;
  margin: 0.1rem;
}

.sidebar-text {
  transition: visibility 0.25s linear;
}

.sidebar > ul.sidebar-section {
  margin-bottom: 1rem;
}

.sidebar > li.sidebar-header {
  font-size: 75%;
  text-transform: uppercase;
  font-weight: 500;
  padding: 0.3rem;
  white-space: nowrap;
  overflow: clip;
  /* color: var(--camel); */
  /* border-bottom: 2px solid var(--dark-sienna); */
}

.sidebar li.sidebar-link {
  cursor: pointer;
  border-radius: 4px;
}

.sidebar li.sidebar-link > a {
  display: block;
  padding: 0.3rem;
  /* white-space: nowrap; */
  overflow: clip;
}

/* .sidebar li.sidebar-link:hover { */
/*   background-color: var(--dark-sienna); */
/* } */
/**/
/* .sidebar-link.selected { */
/*   background-color: var(--dark-sienna); */
/* } */

/* .sidebar-link > a { */
/*   text-decoration: none; */
/*   color: #ccc; */
/* } */
/* .sidebar-link > a:hover { */
/*   color: #eee; */
/* } */

/* @media only screen and (max-width: 600px) { */
/*   .sidebar-container { */
/*     display: none; */
/*   } */
/* } */

@media only screen and (min-width: 1280px) {
  /* always-visible sidebar on larger screens */
  .sidebar-container {
    padding-top: 0;
    width: 240px;
    border-right: 0;
  }
  .sidebar-container .closebtn {
    display: none;
  }
  .sidebar-toggler {
    display: none;
  }
}
