.navbar {
  padding: 0.5rem;
  /* background-color: var(--dark-sienna); */
  /* background-image: linear-gradient(to right, #e56a54, #202a44) !important; */
}

.navbar .logo img {
  max-width: 120px;
}

/* .navbar a { */
/*   color: #ccc; */
/* } */
/**/
/* .navbar a:hover { */
/*   color: #fff; */
/* } */
/**/
/* .navbar a:focus { */
/*   color: #fff; */
/* } */
/**/
/* .navbar-text { */
/*   color: #ccc; */
/* } */

.dropdown-item {
  font-size: 0.85rem;
  padding-top: 0;
  padding-bottom: 0;
}

.dropdown-item > a {
  /* color: #777; */
  text-decoration: none;
  /* font-size: 0.85rem; */
  /* padding-top: 0; */
  /* padding-bottom: 0; */
}

/* .dropdown-item > a:hover { */
/*   color: #333; */
/* } */

/* .dropdown-item.active, */
/* .dropdown-item:active { */
/*   background-color: #ccc !important; */
/* } */

span.nav-text {
  font-size: 0.85rem;
  /* color: gray; */
}

.navbar label {
  font-size: 0.9rem;
  /* color: #ccc; */
  align-self: end;
  margin-left: 0.5rem;
  margin-right: 0.25rem;
}

.navbar select {
  font-size: 0.9rem;
}

.navbar-source-list {
  display: flex;
  flex-direction: row;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  min-width: 300px;
}

.navbar-source-list > label,
.navbar-source-list > select {
  display: inline-block;
}

@media only screen and (min-width: 600px) {
  .navbar-menu {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .navbar-menu {
    display: inherit;
  }
}
